<template>
  <modal :show-modal="showModal" @close-modal="$emit('close-modal')">
    <template v-slot:header>
      <h4>Delete current Scenario?</h4>
    </template>

    <template v-slot:main-content>
      <p>Are you sure you want to delete the current scenario?</p>
    </template>

    <template v-slot:footer>
      <div class="submit-container">
        <custom-button theme='ghost-button' type="button" @click="$emit('close-modal')">Cancel</custom-button>
        <div class="submit-button-container">
          <b-spinner class="mr-2 mt-2" variant="primary" small v-if="isLoading" />
          <custom-button v-else type="button" class="delete-button" :disabled="isLoading" @click="$emit('delete')">Delete Scenario</custom-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import {
  Modal,
  CustomButton
} from 'rwdi-widgets';

export default {
  name: 'DeleteScenarioConfirmationModal',
  components: {
    Modal,
    CustomButton  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style>
</style>