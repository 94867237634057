<template>
  <b-container fluid id="app_header" class="p-0 no-top-margin-all-descendants"> 
    <b-row>
      <b-col>
        <div class="d-flex align-items-center"> 
          <div>
            <slot name="dashboard-icon"></slot>
          </div>

          <div>
            <slot name="title"></slot>
          </div>
        </div>
      </b-col>

      <b-col class="d-flex align-items-center">
        <div>
            <slot name="center-content"></slot>
        </div>
      </b-col>

      <b-col>
        <div class="mr-1 float-right">
          <slot name="right-content"></slot>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'AppHeader'
};
</script>

<style scoped>
#app_header {
  width: 100%;
  background-color: var(--white);
  -webkit-box-shadow: 0 0.125rem 0.5rem hsla(0,0%,73.7%,.4);
  box-shadow: 0 0.125rem 0.5rem hsla(0,0%,73.7%,.4);
  z-index: 99;
}

</style>